@media screen and (max-width: 637px) {
    .headerPart .header-title {
        display: none !important;
    }

    .bgImage {
        background-size: 0px;
    }

    .getQrPart, .filterPart, .resultSuccessPart, .resultFailPart, .pricePart, .informationPart {
        min-height: 0;
        width: 100vw;
        height: auto;
        padding-top: 15%;
        overflow-x: hidden;
    }

    .introductionPart .primarylogo {
        height: 100px;
    }

    .introductionPart .title {
        font-size: 24px;
    }

    .filterPart .description,
    .introductionPart .description,
    .pricePart .description {
        font-size: 19px;
        text-align: center;
    }


    .introductionPart .storebg {
        position: relative;
        height: 250px;
        bottom: 0;
        margin-top: 40px;
    }

    .introductionPart .storebgimg {
        position: relative;
        height: 100%;
    }

    .introductionPart .storebgimg2 {
        display: none;
    }


    .filterPart .title {
        margin-top: 15px !important;
        font-size: 24px;
    }

    .filterPart .titlesecondary {
        font-size: 22px;
    }

    .filterPart .description {
        font-size: 18px;
    }

    .pricePart .title {
        font-size: 24px;
    }

    .pricePart .filterBox {
        border-radius: 15px;
        width: auto;
        margin: 10px 20px;
        padding: 0;
    }

    .pricePart .filterBox .filter {
        margin: 10px;
    }

    .filter .name {
        font-size: 18px;
    }

    .filter .value {
        font-size: 18px;
        margin-top: 5px;
    }

    .pricePart .price {
        font-size: 32px;
        margin: 4% 0;
    }

    .btnGroup {
        margin: auto;
        width: auto;
        justify-content: center !important;
    }

    .btnGroup .btn {
        padding: 4px 24px;
        margin: 10px;
    }

    .getQrPart .primarylogo {
        height: 100px;
        margin-top: 40px;
    }

    .getQrPart .input_mail {
        font-size: 16px;
        width: 80vw;
        margin: 10px 0;
    }

    .resultSuccessPart .title,
    .getQrPart .title {
        font-size: 24px;
        text-align: center;
    }

    .resultFailPart .description,
    .getQrPart .description {
        font-size: 18px;
    }

    .resultFailPart .primarylogo,
    .resultSuccessPart .primarylogo {
        height: 100px;
        margin-top: 40px;
    }

    .resultSuccessPart .logostorebigger {
        height: 80px;
    }

    .resultFailPart .message,
    .resultSuccessPart .message {
        font-size: 18px;
        padding: 0 4%;
        margin: 10px 0 0;
    }

    .resultFailPart .message-highlight,
    .resultSuccessPart .message-highlight {
        margin: 0 8px;
    }


    .informationPart .line1 {
        text-align: center;
        font-size: 20px;
    }

    .informationPart .line2 {
        text-align: center;
        font-size: 20px;

    }

    .informationPart .img1 {
        width: 170px;
        margin-bottom: 20px;
    }

    .informationPart .img2 {
        width: 250px;
    }

    .footerPart {
        width: 100%;
        padding: 10px;
    }

    .footerPart .scocialgroup {
        margin: 20px auto 0;
    }

    .footerPart .moreinformation {
        flex-wrap: wrap;
    }

    .footerPart .moreinformation .info-col {
        margin: 8px 15px;
    }

    .footerPart .moreinformation .coltitle {
        font-size: 18px;
        margin-bottom: 4px;
    }

    .footerPart .moreinformation .colitem {
        font-size: 16px;
    }

    .footerPart .copyrighttext {
        font-size: 16px;
    }

    .qrCodeview {
        height: auto;
        width: 100%;
    }

    .orderRow {
        padding: 0 10px;
    }

    .orders-table table {
        width: 800px;
    }

}